// import './editor.scss'
import React, { FC, useId } from 'react';
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import { Editor, EditorContent, EditorProvider, useCurrentEditor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Paragraph from '@tiptap/extension-paragraph';
import FontFamily from '@tiptap/extension-font-family';
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import Image from '@tiptap/extension-image'
import { useParams } from 'react-router-dom';
import { types } from 'util';
import { ArticleType } from '../../models/article.model';
import { APIBASEURL } from '../../common/constant';
import { DeleteSS } from '../../common/sessionstorage';

interface IMenuBarProps {
    editor: Editor
}
const MenuBar: FC<IMenuBarProps> = ({ editor }) => {
    const addImage = () => {
        const url = window.prompt('URL')

        if (url) {
            editor.chain().focus().setImage({ src: url }).run()
        }
    }

    return (
        <>
            <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleBold()
                        .run()
                }
                className={editor.isActive('bold') ? 'is-active' : ''}
            >
                bold
            </button>
            <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run()
                }
                className={editor.isActive('italic') ? 'is-active' : ''}
            >
                italic
            </button>
            <button
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleStrike()
                        .run()
                }
                className={editor.isActive('strike') ? 'is-active' : ''}
            >
                strike
            </button>
            <button
                onClick={() => editor.chain().focus().toggleCode().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleCode()
                        .run()
                }
                className={editor.isActive('code') ? 'is-active' : ''}
            >
                code
            </button>
            <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                clear marks
            </button>
            <button onClick={() => editor.chain().focus().clearNodes().run()}>
                clear nodes
            </button>
            <button
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={editor.isActive('paragraph') ? 'is-active' : ''}
            >
                paragraph
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >
                h1
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
            >
                h2
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
            >
                h3
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
            >
                h4
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
            >
                h5
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
            >
                h6
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
                bullet list
            </button>
            <button
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
                ordered list
            </button>
            <button
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={editor.isActive('codeBlock') ? 'is-active' : ''}
            >
                code block
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
                blockquote
            </button>
            <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                horizontal rule
            </button>
            <button onClick={() => editor.chain().focus().setHardBreak().run()}>
                hard break
            </button>
            <button
                onClick={() => editor.chain().focus().undo().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .undo()
                        .run()
                }
            >
                undo
            </button>
            <button
                onClick={() => editor.chain().focus().redo().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .redo()
                        .run()
                }
            >
                redo
            </button>
            <button
                onClick={() => editor.chain().focus().setColor('#958DF1').run()}
                className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}
            >
                purple
            </button>
            <button
                onClick={() => editor.chain().focus().setFontFamily('Inter').run()}
                className={editor.isActive('textStyle', { fontFamily: 'Inter' }) ? 'is-active' : ''}
            >
                Inter
            </button>
            <button
                onClick={() => editor.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run()}
                className={
                    editor.isActive('textStyle', { fontFamily: 'Comic Sans MS, Comic Sans' })
                        ? 'is-active'
                        : ''
                }
            >
                Comic Sans
            </button>
            <button
                onClick={() => editor.chain().focus().setFontFamily('serif').run()}
                className={editor.isActive('textStyle', { fontFamily: 'serif' }) ? 'is-active' : ''}
            >
                serif
            </button>
            <button
                onClick={() => editor.chain().focus().setFontFamily('monospace').run()}
                className={editor.isActive('textStyle', { fontFamily: 'monospace' }) ? 'is-active' : ''}
            >
                monospace
            </button>
            <button
                onClick={() => editor.chain().focus().setFontFamily('cursive').run()}
                className={editor.isActive('textStyle', { fontFamily: 'cursive' }) ? 'is-active' : ''}
            >
                cursive
            </button>
            <button onClick={() => editor.chain().focus().unsetFontFamily().run()}>
                unsetFontFamily
            </button>
            <button onClick={addImage}>add image from URL</button>
        </>
    );
};

const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle,
    Document,
    Paragraph,
    Text,
    TextStyle,
    FontFamily,
    Image,
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
];

interface IComposerProps {
    posts?: Array<ArticleType>,
    handleBookmark: (post: ArticleType) => void;
    handleRemoveBookmark: (post: ArticleType) => void;
    handleRemove: (post: ArticleType) => void;
}

const Composer: FC<IComposerProps> = ({ posts, handleBookmark, handleRemoveBookmark, handleRemove }) => {
    const [newArticle, setNewArticle] = React.useState<ArticleType>();
    const [lang, setLang] = React.useState<string>("ta");

    //create or edit post
    let { id } = useParams();

    const handlePushArticles = () => {
        let blogUrl: string = "/api/Blogs/" + lang;
        console.log("Called function");
        fetch(APIBASEURL() + blogUrl, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(newArticle)
        }).catch(error => console.error('Error:', error));
    }

    const editor = useEditor({
        extensions: extensions,
        content: newArticle?.Body,
        onUpdate: ({ editor }) => {
            window.localStorage.setItem('editor-content', editor.getHTML())
            setNewArticle({ Body: editor.getHTML(), Title: newArticle ? newArticle.Title : "", Bookmark: true, ID: posts ? (posts.length + 1).toString() : "1" });
        }
    });

    React.useEffect(() => {
        if (!editor) {
            return undefined
        }
        if (id) {
            if (posts) {
                let oldpost = posts.filter(
                    oldpost => oldpost.ID === id
                )[0];
                if (oldpost) {
                    setNewArticle(oldpost); //edit
                    editor.commands.setContent(oldpost.Body);

                    //delete post
                    posts = posts.filter(
                        oldpost => oldpost.ID !== id
                    );
                }
            }
        }
    }, [editor]);

    const handleSubmit = () => {
        if (newArticle != null) {
            posts?.push(newArticle);
            DeleteSS("blog");
            handlePushArticles();
        }
    }

    const selectId = useId();
    return (
        editor ?
            <>
                <Container className='mt-5' fluid="md" style={{height:"100vh"}}>
                    <Row>
                        <Col className='mt-5'>
                            <label htmlFor={selectId}>Language&nbsp;</label>
                            <select defaultValue="ta" id={selectId} onChange={(e) => {
                                setLang(e.target.value);
                            }}>
                                <option value="ta">Tamil</option>
                                <option value="en">English</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-2'>
                            <input className="form-control" type="text" placeholder="Title of post" value={newArticle?.Title} onChange={(e) => {
                                if (newArticle)
                                    setNewArticle({ ...newArticle, Title: e.target.value });
                            }}></input>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-2'>
                            {/* <EditorProvider slotBefore={<MenuBar />} extensions={extensions} content={content} children={undefined}></EditorProvider> */}

                            <MenuBar editor={editor} />
                            <EditorContent editor={editor} style={{ minHeight: "400px", maxHeight: "600px", overflowY: 'auto' }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Button variant="success" onClick={handleSubmit}>Submit</Button>
                            <ul >
                                <li>
                                    {newArticle ?
                                        newArticle?.Bookmark ?
                                            <button className="btn btn-remove-bookmarks" onClick={() => handleRemoveBookmark(newArticle)} >Remove from Bookmark</button> :
                                            <button className="btn btn-bookmarks" onClick={() => handleBookmark(newArticle)} >Add to Bookmark</button> :
                                        ""}
                                </li>
                                <li>{newArticle ?
                                    <button className="btn btn-remove" onClick={() => handleRemove(newArticle)}>Remove</button> :
                                    ""}
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container >
            </>
            : <p>No data</p>
    );
};

export default Composer;