export const LANGUAGES = [
  { label: "English", code: "en" },
  { label: "தமிழ்", code: "ta" },
];

export const CDN: string = "https://masjidetaqwa.azureedge.net";
export const IMAGEBASE: string = CDN + "/web/images/";
export const AUDIOBASE: string = CDN + "/web/audio/";
const _baseUrl: string = "https://masjidethaqwa.in";


export const APPBASEURL = (): string => {
  if (process.env.NODE_ENV !== 'production') {
    return "http://localhost:3000";
  }
  return _baseUrl;
}
export const APIBASEURL = (): string => {
  if (process.env.NODE_ENV !== 'production') {
    return "http://localhost:7071";
  }
  return "";
}