import React, { FC, useState } from "react";
import Post from "./Post";
import Posts from "./Posts";
import { ArticleType } from "../../models/article.model";


interface IContentProps {
  posts?: ArticleType[];
  handleBookmark: (post: ArticleType) => void;
  handleRemoveBookmark: (post: ArticleType) => void;
}


const Content: FC<IContentProps> = ({ posts, handleBookmark, handleRemoveBookmark }) => {
  React.useEffect(() => {
    // if (!posts || posts.length == 0) {
    //   handlePullArticles();
    // }
  }, []);
  return (
    <div>
      <Posts posts={posts} handleBookmark={handleBookmark} handleRemoveBookmark={handleRemoveBookmark} />
    </div>
  );
}

export default Content;