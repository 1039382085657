export function StoreLS(key: string, value: any, days: number) {
    const now = new Date()
    const millsecinday = 86400000; //1000 * 60 * 60 * 24

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + (days * millsecinday),
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export function GetLS(key: string) {
    const millsecinday = 86400000; //1000 * 60 * 60 * 24
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const today = new Date()

    let Difference_In_Time =
        today.getTime() - item.expiry;


    let Difference_In_Days =
        Math.round
            (Difference_In_Time / millsecinday);

    // compare the expiry time of the item with the current time
    if (Difference_In_Days > 0) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export function DeleteLS(key: string) {
    localStorage.removeItem(key);
}