import * as React from 'react';
import { DuaType } from '../../models/dua.model';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RWebShare } from 'react-web-share';
import { AUDIOBASE } from '../../common/constant';

interface IDuaProps {
    dua: DuaType
}

const Dua: React.FunctionComponent<IDuaProps> = ({ dua }) => {
    const { i18n, t } = useTranslation();
    return (
        <Card className='mt-3' id={dua.PartitionKey + dua.RowKey}>
            <Card.Header className='mt-5 duatamil' > {dua.RowKey} : {dua.ta_Title}
                <a id={"item" + dua.PartitionKey + dua.RowKey} href={"#" + dua.PartitionKey + dua.RowKey} className="hash-link" aria-label={"Direct link to " + dua.ta_Title} title={"Direct link to " + dua.ta_Title}></a>
                <RWebShare
                    data={{
                        text: "\n" + dua.Arabic + "\n\n" + dua.ta_Transliteration.replaceAll(',', ',\n') + "\n\n" + dua.ta_Translation,
                        url: "\n" + "https://masjidethaqwa.in/dua/" + dua.PartitionKey + dua.RowKey,
                        title: "Masjid-e-Taqwa Dua Share" + "\n"
                    }}
                    onClick={() =>
                        console.log("shared successfully!")
                    }
                >
                    <button className="bi bi-share btn btn-success btn-sm float-end">Share</button>
                </RWebShare>
            </Card.Header>
            <Card.Body>
                <p >
                    <div className="duatamil">{dua.ta_predua}</div>
                </p>
                <p >
                    <div className="duaarabic">{dua.Arabic}</div>
                </p>
                <p >
                    <div className="">
                        {dua.audiofilename ?
                            <audio controls loop controlsList="nodownload">
                                <source src={AUDIOBASE + dua.audiofilename} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                            : ""}
                    </div>
                </p>
                <p>
                    <em>
                        <div className="duatamil" dangerouslySetInnerHTML={{ __html: dua.ta_Transliteration.replaceAll(',', ',<br />') }} ></div>
                    </em>
                </p>
                <p>
                    <blockquote>
                        <div className="duatamil" dangerouslySetInnerHTML={{ __html: dua.ta_Translation }} ></div></blockquote>
                </p>
                <footer className="blockquote-footer duatamil">
                    <cite title="Source Title">{t("source")}: {dua.ta_Source}</cite>
                </footer>
            </Card.Body>
        </Card>
    );
};
export default Dua;
