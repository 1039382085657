import * as React from 'react';
import Bookmarks from './Bookmarks';
import { Container, Row, Col } from 'react-bootstrap';
import { ArticleType } from '../../models/article.model';


interface IBookmarkProps {
    bookmarks: ArticleType[]; // Assuming bookmarks is an array of any type
}

const Bookmark: React.FunctionComponent<IBookmarkProps> = ({ bookmarks }) => {
    return (
        <Container className='mt-5' fluid="md" style={{height:"100vh"}}>
            <Row>
                <Col className='mt-5'>
                    <h1>Your Bookmarks</h1>
                    <Bookmarks bookmarks={bookmarks} />
                </Col>
            </Row>
        </Container>
    )
};
export default Bookmark;
