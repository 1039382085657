import * as React from 'react';
import { Container, Dropdown, DropdownButton, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { LANGUAGES } from "../common/constant";
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { useCookies } from 'react-cookie';
import { Button, Modal } from 'react-bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
import { DeleteSS } from '../common/sessionstorage';

interface IHeaderProps {
    // activelang: string,
    // setActiveLang: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Header: React.FunctionComponent<IHeaderProps> = () => {
    const [cookies, setCookie] = useCookies(['lang']);
    const { i18n, t } = useTranslation();
    const [show, setShow] = React.useState(false);
    const handleLang = (lang_code: string) => {
        setShow(false);
        onSelectLang(lang_code);
    }
    const onSelectLang = React.useCallback((lang_code: any, doSaveCookies: boolean = true) => {
        i18n.changeLanguage(lang_code);
        // setActiveLang(lang_code);

        if (doSaveCookies) {
            // expires in 3 months 
            var expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 90);
            setCookie('lang', lang_code, {
                expires: expiryDate
            });
        }
        DeleteSS("blog");

    }, [i18n, setCookie]);

    const onMenuClick = () => {
        // document.getElementsByClassName('navbar-toggler')[0].classList.add('collapsed');
        let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
        element.click();
    }

    const closeMenu = () => {
        // document.getElementsByClassName('navbar-toggler')[0].classList.add('collapsed');
        let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
        if (!element.classList.contains("collapsed")) {
            onMenuClick();
        }
    }

    React.useEffect(() => {
        if (!cookies.lang) {
            setShow(true);
        }
        else {
            onSelectLang(cookies.lang, false);
        }

        let elements: HTMLCollection = document.getElementsByClassName('icon-bar')[0].children;
        Array.from(elements).forEach((child: Element) => {
            if (child.id != "home-floating")
                child.addEventListener("click", closeMenu, false);
        });
    }, [cookies.lang, onSelectLang]);


    return (
        <Navbar bg="dark" fixed="top" data-bs-theme="dark" collapseOnSelect expand="lg" >
            <Container>
                <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
                <LinkContainer to="/">
                    <Navbar.Brand ><img
                        alt=""
                        src="/logo512-white.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}{t("title")}</Navbar.Brand>
                </LinkContainer>
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="mr-auto navitemalignment mob-nav-custom">
                        <LinkContainer to="/">
                            <Nav.Link key="home">{t("home")}</Nav.Link>
                        </LinkContainer>
                        <NavDropdown title={t("service")} id="basic-nav-dropdown" className="mob-nav-custom">
                            <LinkContainer to="/dua">
                                <NavDropdown.Item key="dua">{t("dua")}</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/prayertimes">
                                <NavDropdown.Item key="prayertimes">{t("prayertimes")}</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                        <LinkContainer to="/socialservice">
                            <Nav.Link key="social">{t("social")}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/blog">
                            <Nav.Link key="blog">{t("blog")}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/aboutus">
                            <Nav.Link key="about">{t("about")}</Nav.Link>
                        </LinkContainer>
                    </Nav>

                </Navbar.Collapse>
                <DropdownButton
                    defaultValue={cookies.lang}
                    title={(cookies.lang === "ta" ? "Tamil" : "English")}
                    id="dropdown-lang-menu-align-right"
                    onSelect={(e) => onSelectLang(e)}
                    variant="secondary"
                >
                    {LANGUAGES.map(({ code, label }) => (
                        <Dropdown.Item eventKey={code}>
                            {label}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>

            </Container>
            <div className="icon-bar">
                <a href="https://www.facebook.com/masjidethaqwa" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-facebook" style={{ color: "#4267B2" }}></i>
                </a>
                <a href="https://chat.whatsapp.com/6Bj4e5Xhf0r6G5VY1bQuTo" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-whatsapp" style={{ color: "#128C7E" }}></i>
                </a>
                <a href="#"><i className="bi bi-instagram" style={{ color: "#C13584" }}></i></a>
                <a className="d-block d-sm-none" style={{ color: 'green' }}>
                    <LinkContainer to="/dua">
                        <i className="fa-solid fa-hands-praying" ></i>
                    </LinkContainer>
                </a>
                <a href="#" className="d-block d-sm-none" id="home-floating"><i className="bi bi-list" style={{ color: 'blueviolet' }} onClick={onMenuClick}></i></a>
                <a className="d-block d-sm-none" style={{ color: 'darkslateblue' }}>
                    <LinkContainer to="/">
                        <i className="bi bi-house-heart"></i>
                    </LinkContainer>
                </a>
            </div>
            <Modal show={show} onHide={() => handleLang('en')}>
                <Modal.Header closeButton>
                    <Modal.Title>Language Selection <br /> மொழி தேர்வு</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please select your language? <br /> தயவுசெய்து உங்கள் மொழியைத் தேர்ந்தெடுக்கவும்?</Modal.Body>
                <Modal.Footer >
                    <Button variant="secondary"  onClick={() => handleLang('en')}>
                        English
                    </Button>
                    <Button variant="primary" onClick={() => handleLang('ta')}>
                        தமிழ்
                    </Button>
                </Modal.Footer>
            </Modal>
        </Navbar >
    );
};

export default Header;


