import React, { Component, Suspense, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Router } from 'react-router-dom';
import Home from './pages/Home';
import Dua from './pages/Dua';
import NoPage from './pages/NoPage';
import PrayerTimes from './pages/PrayerTimes';
import Header from './components/Header';
import UnderDevelopment from './pages/UnderDevelopment';
import Footer from './components/Footer';
import Blog from './pages/Blog';
import SinglePost from './components/Blog/SinglePost';
import Bookmark from './components/Blog/Bookmark';
import Composer from './components/Blog/Composer';
import { ArticleType } from './models/article.model';
import { APIBASEURL } from './common/constant';
import i18n from 'i18next';
import { useCookies } from 'react-cookie';
import { GetSS, StoreSS } from './common/sessionstorage';

const App: React.FunctionComponent = () => {

  const [posts, setPosts] = React.useState<ArticleType[]>([]);
  const [cookies] = useCookies(['lang']);

  const handlePullArticles = (forcedb: boolean = false) => {
    let blogsSessionStorage: ArticleType[] | undefined | null;

    if (!forcedb) {
      blogsSessionStorage = GetSS("blog") as ArticleType[];
    }

    if (blogsSessionStorage && blogsSessionStorage.length > 0) {
      setPosts(blogsSessionStorage);
    }
    else {
      // if (!posts || posts.length == 0) {
      let blogUrl: string = "/api/Blogs/" + cookies.lang;
      console.log("Called function");
      fetch(APIBASEURL() + blogUrl)
        .then(response => response.json() as unknown as ArticleType[])
        .then((data: ArticleType[]) => {
          StoreSS("blog", data);
          //save to sessionstorage
          setPosts(data);
        })
        .catch(error => console.error('Error:', error));
      // }
    }
  }

  const handleBookmark = (data: ArticleType) => {
    let bookPosts = posts.map(post => post === data ? ({ ...post, Bookmark: true }) : post);
    setPosts(bookPosts)
  }

  const handleRemoveBookmark = (data: ArticleType) => {
    let bookPosts = posts.map(post => post === data ? ({ ...post, Bookmark: false }) : post);
    setPosts(bookPosts)
  }

  const handleSubmission = (data: ArticleType) => {
    let bookPosts = [data, ...posts];
    setPosts(bookPosts)
  }

  const handleRemove = (post: ArticleType) => {
    let removepost = posts.filter(onepost => onepost !== post);
    setPosts(removepost)
    window.history.back();
    handleWindow();
  }

  const handleWindow = () => {
    window.scrollTo(0, 0);
  }
  console.log("app init!")

  React.useEffect(() => {
    handlePullArticles();
    console.log("app mounting!")
  }, [cookies.lang]);

  return (
    <>
      {
        <BrowserRouter>
          <p style={{ backgroundColor: "gainsboro" }}>
            <Header />
            <Routes>
              <Route index Component={Home} />
              {/* <Route path='/home' Component={Home} /> */}
              <Route path='/dua' Component={Dua} />
              <Route path='/dua/:hash' Component={Dua} />
              <Route path='/prayertimes' Component={PrayerTimes} />
              <Route path='/socialservice' Component={UnderDevelopment} />
              <Route path='/aboutus' Component={UnderDevelopment} />
              <Route path="/blog" Component={(props) =>
                <Blog posts={posts} handleBookmark={handleBookmark} handleRemoveBookmark={handleRemoveBookmark} />} />
              <Route path="/blog/:id" Component={(props) =>
                <SinglePost {...props} posts={posts} />} />
              <Route path="/blog/bookmark" Component={(props) =>
                <Bookmark bookmarks={posts.filter(post => post.Bookmark)} />} />
              <Route path="/blog/editor" Component={(props) =>
                <Composer posts={posts} handleBookmark={handleBookmark} handleRemoveBookmark={handleRemoveBookmark} handleRemove={handleRemove} />} />
              <Route path="/blog/editor/:id" Component={(props) =>
                <Composer posts={posts} handleBookmark={handleBookmark} handleRemoveBookmark={handleRemoveBookmark} handleRemove={handleRemove} />} />
              {/* <Route path="/post/new" render={() => <NewStory handleSubmission={handleSubmission} />} /> */}
              <Route path="*" Component={NoPage} />
            </Routes>
            <Footer />
          </p>
        </BrowserRouter>
      }
    </>
  );
}

export default App;

