import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { ArticleType } from '../../models/article.model';

interface SinglePostProps {
    posts?: Array<ArticleType>;
}

const SinglePost: FC<SinglePostProps> = (
    { posts }) => {
    let { id } = useParams();
    let post: ArticleType = { Bookmark:false, Body:"", Title:"", ID:"0" };

    if (posts) {
        post = posts.filter(post => post.ID === id)[0];
    }

    React.useEffect(() => {
        // if (!posts || posts.length == 0) {
        //     handlePullArticles();
        // }
    }, []);
    return (
        <>
            <Container className='mt-5' fluid="md" style={{height:"100vh"}}>
                <Row>
                    <Col className='mt-5'>
                        <h1>{post?.Title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: post ? post.Body : "" }} />
                    </Col>
                </Row >
            </Container>
        </>
    );
}
export default SinglePost;