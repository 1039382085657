import * as React from 'react';
import { FardPrayerType } from '../../models/fardprayer.model';
import { Alert, Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FardPrayers from './FardPrayers';

interface IPrayerTimesProps {
    fardprayers: FardPrayerType
}
const PrayerTimes: React.FunctionComponent<IPrayerTimesProps> = ({ fardprayers }) => {
    const { i18n, t } = useTranslation();
    const [sunrise, setSunrise] = React.useState("00:00 AM");
    const [sunset, setSunset] = React.useState("00:00 AM");
    const [showAlert, setShowAlert] = React.useState(true);
    const renderFardPrayers = (): JSX.Element[] => {
        return fardprayers.PrayerDetail.map(fardprayer => {
            return <FardPrayers fardprayer={fardprayer} />
        })
    };
    React.useEffect(() => {
        const latitude = 13.0827
        const longitude = 80.2707
        const url = `https://api.sunrisesunset.io/json?lat=${latitude}&lng=${longitude}`

        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setSunrise(() => {
                    return data.results.sunrise;
                });
                setSunset(() => {
                    return data.results.sunset;
                });
            })
            .catch(error => console.error('Error:', error));
    }, []);

    return (
        <>
            <Container>
                <Row >
                    <Col className='loadingcenter'>
                        <h3 className='mt-5'>{t("prayertimes")}</h3>
                    </Col>
                </Row>
                <Row >
                    <Col className='center'>
                        <Table striped bordered hover className='mt-3'>
                            <thead>
                                <tr>
                                    <th>{t("prayer")}</th>
                                    <th>{t("adhan")}</th>
                                    <th>{t("iqamah")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderFardPrayers()}
                            </tbody>
                            <thead>
                                <tr>
                                    <th>{t("sunrise")}</th>
                                    <th colSpan={2}>{sunrise}</th>
                                </tr>
                                <tr>
                                    <th>{t("sunset")}</th>
                                    <th colSpan={2}>{sunset}</th>
                                </tr>
                            </thead>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            showAlert ?
                                <Alert variant="info" onClose={() => setShowAlert(false)} dismissible>
                                    <Alert.Heading>Please note below</Alert.Heading>
                                    <ul>
                                        <li>
                                            Iqamah timing may be outdated. Last updated timings is on {fardprayers.LastUpdatedDate}
                                        </li>
                                    </ul>
                                </Alert> :
                                ""
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default PrayerTimes;