import { FC } from "react";
import { Link } from "react-router-dom";
import { leveningStr } from "../../common/helper";
import { ArticleType } from "../../models/article.model";

interface IBookmarksProps {
  bookmarks: ArticleType[];
}

const Bookmarks: FC<IBookmarksProps> = ({ bookmarks }) => {
  const { length } = bookmarks;
  const showPost = length > 0 ?
    bookmarks.map(({ ID: ID, Title: Title, Body: Body }, index) =>
      <div key={ID} ><span>{(index + 1)}</span><h5>
        <Link to={`/blog/${ID}`}>{Title}</Link></h5><p>{leveningStr(Body, 35)}</p></div>) :
    <p>No Bookmark Found</p>;
  return <div>{showPost}</div>
}

export default Bookmarks;