import Image from 'react-bootstrap/Image';
import * as React from 'react';
import { IMAGEBASE } from '../common/constant';
import { Container } from 'react-bootstrap';

function UnderDevelopment() {

    return <>
        <Container className="mt-5 d-flex align-items-center justify-content-center" fluid="md" style={{height:"100vh"}}>
            <Image src={IMAGEBASE + "under-construction.png"} fluid className="mt-5"/>
        </Container>

    </>
}

export default UnderDevelopment;