import React, { useState } from "react";
import Header from "../components/Header";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { APIBASEURL } from "../common/constant";
import { FardPrayerType } from "../models/fardprayer.model";
import PrayerTimes from "../components/Prayer/PrayerTimes";

export default function () {
  const { i18n, t } = useTranslation();
  const [fardprayers, setFardPrayers] = useState<FardPrayerType>();
  const [showLoadings, setShowLoading] = React.useState(true)

  React.useEffect(() => {
    //fard timing
    let fardUrl: string = "/api/FardPrayers";

    //fard timing
    fetch(APIBASEURL() + fardUrl)
      .then(response => response.json())
      .then((data: FardPrayerType) => {
        console.log(data);
        setFardPrayers(() => {
          return data
        });
        setShowLoading(false);
      })
      .catch(error => console.error('Error:', error));
  }, []);
  return (
    <>
      {/* <Header /> */}
      <Container className='mt-5' fluid="md" style={{height:"100vh"}}>
        <Row>
          <Col>
            {fardprayers?.PrayerDetail ?
              <PrayerTimes fardprayers={fardprayers}/> :
              ""
            }
          </Col>
        </Row>
        <Row >
          <Col className='center'>
            {showLoadings ?
              <Button id='loading' variant="primary">
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
              : null}
          </Col>
        </Row>
      </Container>
    </>
  );
}
