import * as React from 'react';
import Post from './Post';
import { ArticleType } from '../../models/article.model';


interface IPostsProps {
    posts?: ArticleType[];
    handleBookmark: (post: ArticleType) => void;
    handleRemoveBookmark: (post: ArticleType) => void;
}

const Posts: React.FunctionComponent<IPostsProps> = ({ posts, handleBookmark, handleRemoveBookmark }) => {
    let showPost: any=<h3>No posts available!</h3>;
    if (posts && posts.length > 0) {
        showPost = posts.map(post =>
            <Post key={post.ID} post={post} handleBookmark={handleBookmark} handleRemoveBookmark={handleRemoveBookmark} />);
    }
    
    return (
        <div>{showPost}</div>
    );
};
export default Posts;
