import Header from "../components/Header";
import React from 'react';

export default function () {
    return (
        <>
            {/* <Header /> */}
            <h2>Error 404: Not found</h2>
        </>
    )
}
