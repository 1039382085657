import Content from "../components/Blog/Content";
import React, { FC, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Bookmarks from "../components/Blog/Bookmarks";
import { ArticleType } from "../models/article.model";
import { GetLS } from "../common/localstorage";


interface IBlogState {
    posts?: ArticleType[];
    handleBookmark: (post: ArticleType) => void;
    handleRemoveBookmark: (post: ArticleType) => void;
}

const Blog: FC<IBlogState> = ({ posts, handleBookmark, handleRemoveBookmark }) => {
    let bookmarks: ArticleType[];
    const [blogs, setBlogs] = useState<ArticleType[]>([]);

    React.useEffect(() => {
        // if (!posts || posts.length == 0) {
        //     handlePullArticles();
        //     // posts = GetLS("blog") as ArticleType[];
        //     // if (posts) {
        //     //     bookmarks = posts.filter(post => post.Bookmark);
        //     // }
        // }
    }, []);
    return (
        <>
            {
                <Container className='mt-5' fluid="md" style={{height:"100vh"}}>
                    <Row >
                        <Col className='mt-5'>
                            <h2>Latest Post</h2>
                            <Content posts={posts} handleBookmark={handleBookmark} handleRemoveBookmark={handleRemoveBookmark} />
                        </Col >
                        <Col className='mt-5 d-none d-lg-block d-xl-block' md={4}>
                            <h2>Must watch</h2>
                            {/* <Bookmarks bookmarks={bookmarks} /> */}
                        </Col >
                    </Row>
                </Container >
            }
        </>
    );
}
export default Blog;