import * as React from 'react';
import { FardPrayerType, PrayerDetailType } from '../../models/fardprayer.model';

interface IFardPrayersProps {
    fardprayer: PrayerDetailType
}

const FardPrayers: React.FunctionComponent<IFardPrayersProps> = ({ fardprayer }) => {
    return (
        // <div className='mb-3'>
        <tr>
            <td>{fardprayer.Prayer}</td>
            <td>{fardprayer.Adhan}</td>
            <td>{fardprayer.Iqamah}</td>
        </tr>

        // </div>
    );
};

export default FardPrayers;
