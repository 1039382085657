import React, { useState } from 'react';
import { DuaType } from '../models/dua.model';
import { Accordion, Button, Col, Container, Dropdown, Navbar, Row, Spinner } from 'react-bootstrap';
import Duas from '../components/Dua/Duas';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { APIBASEURL } from '../common/constant';
import { GetLS, StoreLS } from '../common/localstorage';
import Color from '@tiptap/extension-color';
import Form from 'react-bootstrap/Form';

export default function () {
  const { i18n, t } = useTranslation();
  const [duas, setDuas] = useState<DuaType[]>([]);
  const bootstrap: any = undefined;
  const [showLoadings, setShowLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  let hashCounter: number = 0;
  const TimerAsync = (ms: number) => new Promise(res => setTimeout(res, ms))

  console.log("Dua Page Load");

  //hyperlink to dua
  let { hash } = useParams();

  window.addEventListener("scroll", function () {
    var toolbar = document.getElementById("toolbar");
    if (toolbar) {
      if (window.pageYOffset > 0) {
        toolbar.classList.add("fixed");
      } else {
        toolbar.classList.remove("fixed");
      }
    }
  });

  //call back when hash found
  const hashCallback = async () => {
    let MaxRetry = 30;
    var letr = hash?.match(/[a-zA-Z]+/g);
    if (letr) {
      while (!document.getElementById(letr[0])?.children) {
        console.log("has header not found!")
        hashCounter++;
        await TimerAsync(2000);
        if (hashCounter > MaxRetry) // maximum limit to retry(ex. 60 sec)
          return;
      }
      if (hashCounter > MaxRetry) // maximum limit to retry(ex. 60 sec)
        return;
      console.log("has header found!")
      hyperlinkClick(letr);
    }
  };


  const hyperlinkClick = (letr: RegExpMatchArray) => {
    let header = document.getElementById(letr[0])?.children[0] as HTMLElement;
    if (header) {
      if (header.classList.contains("collapsed")) {
        console.log("has header is clicked");
        header.click();
      }
      else {
        console.log("header is not collapsed");
      }
      console.log("child is called to focus");
      setTimeout(subHyperlinkClick, 500);
    }
  };

  const subHyperlinkClick = () => {
    if (hash) {
      document.getElementById("item" + hash)?.click();
    }
  };
  const refreshDua = () => {
    setShowLoading(true);
    DuaAPI(null, true);
  };
  const collapseAll = () => {
    // ((document.querySelector('.show')as HTMLElement).parentNode?.querySelector(".accordion-button") as HTMLElement).click()
    var collapseElementList = [].slice.call(document.querySelectorAll('.show'))
    collapseElementList.map(function (collapseEl: HTMLElement) {
      let headerButton = collapseEl.parentNode?.querySelector(".accordion-button") as HTMLElement;
      setTimeout(function () {
        headerButton.click();
      }, 100);
      // headerButton.click();
      console.log(headerButton.innerText);
    });
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setSearchTerm(value);
    filterData(value);
  };

  const filterData = (searchTerm: string) => {
    let duaslocalstorage: DuaType[] | null = GetLS("duas");
    if (duaslocalstorage) {
      searchTerm = searchTerm.trim().toLowerCase();

      const filteredData = (duaslocalstorage).filter((item) => {
        return (
          item.Arabic?.toLowerCase().includes(searchTerm) ||
          item.en_Transliteration?.toLowerCase().includes(searchTerm) ||
          item.ta_Transliteration?.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          item.ta_Category?.includes(searchTerm.trim().toLowerCase()) ||
          item.en_Category?.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          item.en_Translation?.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          item.ta_Translation?.includes(searchTerm.trim().toLowerCase()) ||
          item.ta_Source?.includes(searchTerm.trim().toLowerCase()) ||
          item.en_Source?.toLowerCase().includes(searchTerm.trim().toLowerCase())
        );
      });
      setDuas(filteredData);
    }
  };


  const DuaAPI = (query: string | null = null, force: boolean = false) => {
    let duaslocalstorage: DuaType[] | null = null;
    if (!force) {
      // duaslocalstorage = localStorage.getItem("duas");
      duaslocalstorage = GetLS("duas");
    }
    if (duaslocalstorage) {
      setDuas(() => {
        return duaslocalstorage as DuaType[];
      });
      setShowLoading(false);
    }
    else {
      let filter: string = "";
      if (query) {
        filter = "?category=" + query;
      }
      let duaUrl: string = "/api/Duas" + filter;
      fetch(APIBASEURL() + duaUrl)
        .then(response => response.json())
        .then((data: DuaType[]) => {
          //save to localstorage
          //localStorage.setItem("duas", JSON.stringify(data));
          StoreLS("duas", data, 7)

          setDuas(() => {
            return data;
          });
          setShowLoading(false);
        })
        .catch(error => console.error('Error:', error));
    }
  }

  React.useEffect(() => {
    DuaAPI();
    if (hash) {
      setTimeout(hashCallback, 500);
    }
  }, []);
  return (
    <>
      {/* <Header /> */}
      {/* <nav className="mt-5 navbar navbar-expand-lg navbar-light bg-light">
        <i role='button' className="bi bi-arrows-collapse " onClick={collapseAll}></i>
        <Button variant="primary" className='float-end' onClick={refreshDua}>{t("refresh")}</Button>
      </nav> */}
      <nav className="mt-5 default fixed-top duabar" style={{ color: "aqua", zIndex: "100" }} >
        {/* <div className="container mt-2 ml-2"> */}
        <Container>
          <Row>
            <Col className='mt-2 ml-2 justify-content: center' >
              <i role='button' className="bi bi-arrow-clockwise" title={t("refresh")} onClick={refreshDua} >{t("refresh")}</i>&nbsp;&nbsp;
              <i role='button' className="bi bi-arrows-collapse" title={t("collapse")} onClick={collapseAll}>{t("collapse")}</i>
            </Col>
          </Row>
        </Container>
      </nav >
      <Container className='duacontent' fluid="md" style={{height:"100vh"}}>
        <Row >
          <Col>
            <Accordion alwaysOpen id="accordionDua">
              <Form className="d-flex mb-3">
                <Form.Control
                  type="search"
                  placeholder="Search Dua"
                  className="me-2"
                  aria-label="Search Dua"
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </Form>
              <Duas duas={duas} />
            </Accordion>
          </Col>
        </Row>
        <Row >
          <Col className='center ' >
            {showLoadings ?
              <Button id='loading' variant="primary">
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
              : null}
          </Col>
        </Row>
      </Container>
    </>
  )
}