import * as React from 'react';
import { Link } from 'react-router-dom';
import { leveningStr } from '../../common/helper';
import { ArticleType } from '../../models/article.model';


interface IPostProps {
    post?: ArticleType;
    handleBookmark: (post: ArticleType) => void;
    handleRemoveBookmark: (post: ArticleType) => void;
}

const Post: React.FunctionComponent<IPostProps> = ({ post, handleBookmark, handleRemoveBookmark }) => {
    return (
        post?
            <>
                <div>
                    <h3><Link to={`/blog/${post.ID}`}>{post.Title}</Link></h3>
                    {/* <p dangerouslySetInnerHTML={{ __html: leveningStr(post.Body, 250) }} /> */}
                    <ul>
                        <li><Link to={`/blog/${post.ID}`} >Read More</Link></li>
                        <li>
                            {post.Bookmark ?
                                <button onClick={() => handleRemoveBookmark(post)} >Remove from Bookmark</button> :
                                <button onClick={() => handleBookmark(post)} >Add to Bookmark</button>}
                        </li>
                    </ul>
                </div>
            </> : <div></div>
    );
};
export default Post;
