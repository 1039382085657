import * as React from 'react';
import { Container, Navbar } from 'react-bootstrap';

interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
    return (
        <Container className='mt-4 margin-top:auto' data-bs-theme="dark">
            <Navbar className="bg-body-tertiary justify-content: center">
                    <small><b>TNTJ Nerkundram Branch</b></small>
            </Navbar>
        </Container>
    );
};
export default Footer;
