
export function StoreSS(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value))
}

export function GetSS(key: string) {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    return item.value
}

export function DeleteSS(key: string) {
    sessionStorage.removeItem(key);
}